<template>
    <div class="main">
        <div class="top-tap">
            <van-radio-group v-model="postData.isOpen" @change="onRadio" direction="horizontal">
                <div class="select-box-radio">
                    <van-radio :name="1">限制会员</van-radio>
                    <van-radio :name="2">限制群聊</van-radio>
                </div>
            </van-radio-group>
        </div>
        <div v-if="postData.isOpen === 2" class="list-box">
            <div v-if="list.length>0">
                <van-checkbox-group v-model="postData.groupIdList">
                    <van-cell-group>
                        <van-cell
                                v-for="(item, index) in list"
                                clickable
                                :key="index"
                                :title="item.name + '('+item.memberCount+')'"
                                @click="toggle(index)"
                        >
                            <template #right-icon>
                                <van-checkbox :name="item.groupId" ref="checkboxes" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-checkbox-group>
            </div>
            <div v-else class="default-box">
                <div style="font-size: 30px"><van-icon name="warning-o" /></div>
                <div>暂无群聊</div>
            </div>
        </div>
        <div v-else class="default-box">
            <div style="font-size: 30px"><van-icon name="warning-o" /></div>
            <div>非会员将不能打开分享链接</div>
        </div>
        <div style="height:80px"></div>
        <div class="bottom-box" @click="saveData">
            <div class="bottom-right">提交</div>
        </div>
    </div>
</template>

<script>
    // import { Toast } from 'vant';
    import { getGroupList, saveConfig } from '@/api/live'
    import Cookies from "js-cookie";
    import {Toast} from "vant";

    export default {
        name: 'Login',
        data() {
            return {
                userId: '',
                liveId: '', // 直播id
                list: '',
                id: '',
                radio: '',
                result: [],
                postData:{
                    groupIdList: [],
                    isOpen: 2,
                    livingId: ""
                }
            }},
        created() {
            let query=this.$route.query;
            if(!query.liveId || !query.id){
                Toast('参数错误')
                return
            }
            this.liveId = query.liveId
            this.id = query.id
            this.postData.livingId = query.liveId
            let userId=Cookies.get('userId')
            if(!userId){
                this.$router.push({ name: 'liveList', query: {}});
            }else {
                this.userId = userId
                this.getGroup()
            }
        },
        mounted:function(){
        },
        methods: {
            onRadio(){
                if(this.postData.isOpen === 1){
                    this.postData.groupIdList = []
                }
            },
            toggle(index) {
                this.$refs.checkboxes[index].toggle();
                console.log(this.postData)
            },
            // 获取群列表
            getGroup(){
                let postData={}
                postData.anchorUserid=this.userId
                postData.livingId = this.liveId
                getGroupList(postData).then(response => {
                    console.log(response)
                    this.list = response.data.list
                    this.postData.isOpen = response.data.isOpen
                    // 格式化数据
                    for(let i = 0; i<this.list.length;i++){
                        if(this.list[i].check === true){
                            this.postData.groupIdList.push(this.list[i].groupId)
                        }
                    }
                })
            },
            // 保存信息
            saveData() {
                Toast.loading({
                    message: '保存中...',
                    forbidClick: true,
                });
                console.log(this.postData)
                saveConfig(this.postData).then(response => {
                    console.log(response)
                    Toast.clear()
                    if(response.code === 0 || response.code === 200){
                        this.$router.push({ name: 'LiveList', query: {}});
                        return
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        min-height: 100vh;
        position: relative;
        background-color: #ffffff;
    }
    .top-tap{
        position: fixed;
        top: 0;
        left: 0;
        background-color: #F8F8F8;
        height: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 20px;
        z-index: 100;
    }
    .select-box-radio{
        display: flex;
        justify-content: space-between;
        width: calc(100vw - 40px);
    }
    .list-box{
        padding:0 20px;
        margin-top: 80px;
    }
    .select-line{
        margin: 10px 0 0 0;
    }
    .bottom-box{
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: center;
        width: 100vw;
        background-color: #ffffff;
        padding: 20px 0;
    }
    .bottom-right{
        width: calc(100vw - 40px);
        height: 40px;
        line-height: 40px;
        background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
        box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
        border-radius: 2px;
        text-align: center;
        color: #ffffff;
    }
    .default-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        text-align: center;
        color: #a5a4a4;
        font-size: 14px;
    }
</style>
